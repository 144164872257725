<template>
  <div class="seven">
    <div class="card-content mx-auto">
      <div class="img-content">
        <img src="@/assets/img/home/arrow-left-bold.png" class="left" @click="onPreview" />
      </div>
      <ul class="card flex container mx-auto" id="card" ref="tabsRef">
        <li
          v-for="(item, index) in industryList"
          :key="index"
          class="other-tab"
          :class="['card-col', industryIndex == index && 'card-col-selected']"
          @mouseenter="tabsOnMove(index)"
          @click="tabsOnMove(index)"
        >
          <div class="h-100 flex flex-col align-center justify-center">
            <img :src="item.url" />
            <p>{{ item.title }}</p>
          </div>
        </li>
      </ul>
      <div class="img-content">
        <img src="@/assets/img/home/arrow-right-bold.png" class="right" @click="onNext" />
      </div>
    </div>
    <!-- <div class="pagation flex justify-center">
      <div @click="onPreview"><img src="@/assets/img/left.png" /></div>
      <div @click="onNext"><img src="@/assets/img/right.png" /></div>
    </div> -->
    <div class="flex justify-between align-center container mx-auto">
      <div class="industry-info">
        <p class="two-leave-title">{{ industryInfoList[industryIndex].title }}</p>
        <p class="two-leave-title-two mt-20">{{ industryInfoList[industryIndex].twoTitle }}</p>
        <p class="three-leave-title">{{ industryInfoList[industryIndex].firstIntroduce }}：</p>
        <p class="industry-info-content">
          {{ industryInfoList[industryIndex].desc }}
        </p>
        <p class="three-leave-title">{{ industryInfoList[industryIndex].twoIntroduce }}：</p>
        <p class="industry-info-content">
          {{ industryInfoList[industryIndex].twoDesc }}
        </p>
        <button class="look-detail">咨询客服</button>
      </div>
      <div class="right">
        <img :src="images[industryIndex].url" class="industry-image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      industryIndex: 0,
      industryList: [
        {
          url: require('@/assets/img/net.png'),
          title: '互联网',
          key: 0
        },
        {
          url: require('@/assets/img/car.png'),
          title: '汽车',
          key: 1
        },
        {
          url: require('@/assets/img/jinrong.png'),
          title: '金融',
          key: 2
        },
        {
          url: require('@/assets/img/house.png'),
          title: '地产',
          key: 3
        },
        {
          url: require('@/assets/img/safe.png'),
          title: '保险',
          key: 4
        },
        {
          url: require('@/assets/img/government.png'),
          title: '政府单位',
          key: 5
        },
        {
          url: require('@/assets/img/operator.png'),
          title: '运营商体系',
          key: 6
        }
      ],
      industryInfoList: [
        {
          title: '互联网',
          desc: '某集团为更好的激励员工、提高和增强用户的积极性与粘性，通过全行业全场景优惠券的营销因素，提升员工幸福度和用户满意度，并实现不同渠道的引流。',
          url: '',
          twoTitle: '',
          firstIntroduce: '项目场景',
          twoIntroduce: '解决方案',
          twoDesc:
            '项目小组通过与客户深入交流，迅速展开客户需求调研、明确各环节开发工作：发放规则、商城搭建及对接、虚拟兑换商品接口、操作系统培训及支持、7x24小时兑换服务响应、全国配送及售后服务。高效完成平台搭建、礼品供应、售后服务、数据统计分析等工作。 平台搭建，礼品供应、售后服务，数据统计分析等服务。'
        },
        {
          title: '汽车',
          desc: '旨在制定某汽车会员商城运营策略、实施定制化商品开发策略、产品策略、平台功能及UI界面优化策略，策划执行日常及各类促销活动、内容板块规划，视觉及文案输出等。从“人、车、生活”全面深耕客户，提升销量、实现会员价值转化，扩大汽车品牌影响力。',
          url: '',
          twoTitle: '',
          firstIntroduce: '项目场景',
          twoIntroduce: '解决方案',
          twoDesc:
            '璐付科技通过研究行业内外同类型会员商城运营发展现状及趋势，为该汽车会员商城运营提供专业意见。制定会员商城运营整体策略与规划，根据客户属性明确商城定位，提供包括但不仅限于运营策略、产品策略、功能及UI界面优化、内容板块规划，视觉及文案输出等服务。设计有品质、可提升品牌形象的产品，实现具有品牌特色的产品定制化，并充分与品牌文化结合。制定客户产品策略和规划，根据客户属性设计专属产品，将汽车及互联网等行业先进理念和成功案例与客户需求相结合，对商城未来2年运营策略进行规划。策划执行日常销售及各类主题促销活动，结合节气和当下热点，不断创新，创意设计实施商品上新、秒杀、会员日、超级会员日，好物节等促销活动。'
        },
        {
          title: '金融',
          desc: '随着市场发展、用户喜好的迁移，为更好的服务用户，提高品牌知名度，提升企业存量市场运营效率，开拓新增量市场，赋能企业数字化变革。',
          url: '',
          twoTitle: '',
          firstIntroduce: '项目场景',
          twoIntroduce: '解决方案',
          twoDesc:
            '根据该银行的活动需求，璐付科技通过自身的底层营销服务生态圈的能力，提供运营策划、产品策略、功能及UI界面优化、内容板块规划、视觉文案输出、宣传策略等专业化产品及服务。营销活动每经上线均深受用户喜爱，用户参与度单日最高GMV80+万。'
        },
        {
          title: '房地产',
          desc: '用户增长依赖线下自然流量，触达消费者渠道单一，高净值客户在消费结束后缺乏沟通渠道和反馈方式，无法以业务触点连接客户数字化营销并持续运营。品牌多业态经营客户资源无法打通，缺乏全业态数字营销规划和品牌数字资产沉淀。',
          url: '',
          twoTitle: '',
          firstIntroduce: '项目场景',
          twoIntroduce: '解决方案',
          twoDesc:
            '璐付项目小组通过客户需求调研，制定福利积分解决方案，迅速开展各环节开发工作，实现福利积分兑换商城搭建及上线、实物兑换商品供应、虚拟兑换商品供应、提供操作系统培训及支持、7×24小时兑换服务响应、全国配送和售后、数据统计分析等服务。帮助企业实现会员营销的数字化转型和跨行业交叉营销。 '
        },
        {
          title: '保险',
          desc: '某保险企业为维护会员感情，适应市场营销发展需求，进一步增强竞争力，急需建立客户综合积分营销管理系统，同时希望定制一套会员增值服务解决方案。通过互联网科技和系统创新来吸引潜在客户，提高会员忠诚度，增加产品销量为企业带来更多盈利。',
          url: '',
          twoTitle: '',
          firstIntroduce: '项目场景',
          twoIntroduce: '解决方案',
          twoDesc:
            '璐付科技根据客户需求提出营销方案，为客户搭建积分管理系统，客户可在后台根据不同的保险要求配置不同的积分奖励和活动规则。积分商城通过接口形式与客户的公众号、app实现数据交互。运营过程中绑定保险营销计划，精选与节日主题性关联较强的商品进行优化组合，打造主题商品促销专区，设置科学活动频次，使用户粘性提升300%。有效刺激用户购买产品或服务的意愿和积极性，提高用户忠诚度，提升客户综合收益，降低运营管理成本。%。有效刺激客 户购买产品或服务的意愿和积极性，提升客户忠诚度，提高客户综合收益贡献，并降低运营管理成本。'
        },
        {
          title: '政府',
          desc: '传统福利模式下，企业福利体验感无法得到有效提升，某工会为了更好地激励员工、提高员工工作积极性，希望能针对性地开发定制福利平台，通过积分体系有效提升员工幸福度。',
          url: '',
          twoTitle: '',
          firstIntroduce: '项目背景',
          twoIntroduce: '解决方案',
          twoDesc:
            '与客户进行深入交流后，立即成立福利商城项目小组，并迅速展开客户需求调研、明确各环节的开发工作：福利积分发放规则、福利积分兑换商城搭建及上线、实物兑换商品供应、虚拟兑换商品接口、操作系统培训及支持、7x24小时兑换服务响应、全国配送和售后、数据分析等。通过打通线上线下，丰富员工线上消费场景和用户路径，优化兑换购物体验，为员工提供全面便利的福利体验。'
        },
        {
          title: '运营商',
          desc: '某运营商的用户权益98%以上是全国性通用权益，缺少针对属地化的权益。在与其他运营商进行属地化的用户竞争中没有明显优势，当地C端用户在属地化的活动参与中不够活跃，难以实现二次转化和潜在消费能力的深度挖掘以及品牌宣传的渗透。',
          url: '',
          twoTitle: '',
          firstIntroduce: '项目场景',
          twoIntroduce: '解决方案',
          twoDesc:
            '璐付科技根据该运营商的需求提供了营销解决方案，通过璐付科技的底层营销服务生态圈能力，为该运营商打造了覆盖属地化C端用户的吃喝玩乐，衣食住行等权益，构建了与其他运营商在属地化C端用户营销上的营销壁垒，使其品牌形象更好更快的融入和渗透到用户的方方面面。通过为该运营商搭建属地化的权益营销系统，以接口的形式与客户的公众号、app实现数据交互。同时在营销运营过程中与属地化营销计划绑定，有效刺激用户的活跃性，提升忠诚度。全年实现该运营商在属地化C端用户营销创收版块的利润同比增收287%。'
        }
      ],
      images: [
        {
          url: require('@/assets/img/home/flw.jpg')
        },
        {
          url: require('@/assets/img/home/qchy.jpg')
        },
        {
          url: require('@/assets/img/home/jr.jpg')
        },
        {
          url: require('@/assets/img/home/fdc.jpg')
        },
        {
          url: require('@/assets/img/home/baoxian.jpg')
        },
        {
          url: require('@/assets/img/home/zf.jpg')
        },
        {
          url: require('@/assets/img/home/yys.jpg')
        }
      ]
    };
  },
  methods: {
    /** 事件#上一页 */
    onPreview() {
      if (this.industryIndex > 0) this.industryIndex--;
      if (this.industryIndex <= 3) {
        this.$refs.tabsRef.scrollTo({
          left: 0,
          behavior: 'smooth'
        });
      }
    },
    /** 事件#上一页 */
    onNext() {
      if (this.industryIndex < this.industryList.length - 1) this.industryIndex++;
      if (this.industryIndex >= 3) {
        this.$refs.tabsRef.scrollTo({
          left: 480,
          behavior: 'smooth'
        });
      }
    },
    tabsOnclick(e, index) {
      this.industryIndex = index;
      this.tabsClickScroll('tabsRef', e);
    },
    tabsOnMove(index) {
      this.industryIndex = index;
    },
    tabsClickScroll(refDom, e) {
      let scrollLeft = null;
      let scrollRight = null;
      let moveDistance = null;
      const clientWidthHalf = this.$refs[refDom].clientWidth / 2;

      // e.layerX获取元素相对refDom元素的位置
      moveDistance = clientWidthHalf - e.layerX;

      scrollLeft = moveDistance;
      scrollRight = -moveDistance;

      // 获取当前元素所在元素的中间位置
      const currentMiddleHalf = (this.$refs[refDom].clientWidth + e.target.offsetWidth) / 2;
      // 点击左侧
      // layerX 鼠标相比较于当前坐标系的位置,即如果触发元素没有设置绝对定位或相对定位,以页面为参考点,如果有,将改变参考坐标系
      if (e.layerX <= currentMiddleHalf) {
        console.log(scrollLeft, 'scrollLeft');
        this.$refs[refDom].scrollTo({
          left: -scrollLeft - 480,
          behavior: 'smooth'
        });
        // this.$refs[refDom].scrollLeft -= scrollLeft;
      } else {
        // 点击右侧
        // this.$refs[refDom].scrollLeft += scrollRight;
        console.log(scrollRight, 'scrollRight');
        this.$refs[refDom].scrollTo({
          left: scrollRight + 480,
          behavior: 'smooth'
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.seven {
  .card {
    width: 100%;
    height: 130px;
    background-color: @lupay-bg-color-white;
    overflow: scroll;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      // chrome safari
      display: none;
    }
    width: 1200px;
    height: 150px;
    box-shadow: 0px 3px 10px rgba(34, 78, 219, 0.1);
    cursor: pointer;
    &-col {
      min-width: 240px;
      height: 100%;
      img {
        width: 42px;
        height: 42px;
        margin-bottom: 10px;
      }
    }
    &-col:hover {
      background-color: @lupay-bg-color-hover-1;
      transition: all 0.3s;
    }
    &-col-selected {
      background-color: @lupay-bg-color-hover-1;
      transition: all 0.3s;
    }
  }
  .card-content {
    position: relative;
    width: 1300px;
    .img-content {
      display: none;
      .left,
      .right {
        position: absolute;
        width: 40px;
        height: 40px;
        top: calc(50% - 20px);
        cursor: pointer;
      }
      .left {
        // left: -60px;
      }
      .right {
        right: 0;
      }
    }
  }
  .card-content:hover {
    .img-content {
      display: block;
    }
  }
  .pagation {
    margin-top: 24px;
    img {
      width: 40px;
      height: 20px;
      margin: 0 24px;
      cursor: pointer;
    }
    img:hover {
    }
  }
  .industry-info {
    margin: 39px 0 0 56px;
    .two-leave-title {
      color: @lupay-text-color-black-3;
      font-size: 22px;
    }
    .three-leave-title {
      margin: 22px 0 17px 0;
      color: @lupay-text-color-black-3;
      font-size: 14px;
    }
    &-content {
      width: 448px;
      color: @lupay-text-color-black-4;
      font-size: 14px;
    }
    .look-detail {
      margin: 19px 0 77px 0;
      width: 126px;
      height: 43px;
      color: @lupay-text-color-white;
      background: linear-gradient(
        125.78deg,
        @lupay-text-color-theme 0%,
        rgba(38, 81, 220, 1) 50%,
        rgba(231, 238, 252, 1) 100%
      );
      border-radius: 6px;
      position: relative;
    }
    .look-detail:hover::before {
      content: '微信扫一扫';
      display: block;
      width: 126px;
      top: 43px;
      left: -1px;
      position: absolute;
      background-size: 100% 100%;
      padding: 2px;
      border: 1px solid #f4f4f4;
      opacity: 1;
      transition-duration: 300ms;
      background-color: #fff;
      z-index: 8;
      text-align: center;
      color: @lupay-text-color-theme;
    }

    .look-detail:hover::after {
      content: '';
      display: block;
      width: 126px;
      height: 126px;
      top: 69px;
      position: absolute;
      background-image: url('../../assets/img/lupaywechat.png');
      background-size: 100% 100%;
      padding: 2px;
      border: 1px solid #f4f4f4;
      opacity: 1;
      transition-duration: 300ms;
    }
  }
  .industry-image {
    max-width: 600px;
    max-height: 300px;
    transition-duration: 300ms;
    margin: 0 50px;
  }
  .industry-image:hover {
    transform: scale(1.1);
    transition-duration: 300ms;
  }
}
</style>
