<template>
  <div>
    <div class="img" :style="imgStyle">
      <div v-if="showText">
        <div class="info" :class="{ show }">
          <div class="flex align-center">
            <div class="cicle"></div>
            <div class="title">{{ source.text.title }}</div>
          </div>
          <div class="two-title">{{ source.text.twoTitle }}</div>
          <div class="desc">{{ source.text.desc }}</div>
        </div>
        <button class="look-detail" :class="{ show }" v-if="showText" @click="onNavTo(source.routerName)">
          查看详情
        </button>
      </div>
      <div class="default-info" v-else>
        <div class="default-text">{{ source.text.defaultText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import TWEEN from '@tweenjs/tween.js';
export default {
  name: 'girdImage',
  props: {
    source: {
      type: Object,
      default: () => {}
    },
    transform: {
      type: Object,
      default: () => {}
    },
    showText: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      l: this.transform.left,
      t: this.transform.top,
      w: this.transform.width,
      h: this.transform.height,
      act: false,
      show: false
    };
  },
  computed: {
    imgStyle() {
      return {
        backgroundImage: `url(${typeof this.source === 'string' ? this.source : this.source.url})`,
        left: `${this.l}px`,
        top: `${this.t}px`,
        width: `${this.w}px`,
        height: `${this.h}px`
      };
    }
  },
  watch: {
    transform: {
      handler(n, o) {
        this.trans(n, o);
      },
      deep: true
    }
  },
  methods: {
    animate() {
      TWEEN.update();
      if (this.act) requestAnimationFrame(this.animate);
    },
    onNavTo(name) {
      this.$router.push({
        name
      });
    },
    trans(newValue, oldValue) {
      this.act = true;
      let tween = new TWEEN.Tween(oldValue);
      tween
        .to(newValue, 380)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onUpdate(val => {
          this.l = val.left;
          this.t = val.top;
          this.w = val.width;
          this.h = val.height;
        })
        .onComplete(() => {
          this.act = false;
          this.show = this.showText;
        })
        .start();
      this.animate();
    }
  }
};
</script>

<style scoped lang="less">
.img {
  background-position: center;
  background-size: center center;
  position: absolute;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: bottom;
    width: 0;
  }
}
.txt {
  background: rgba(0, 0, 0, 0.75);
  color: #ffffff;
  display: inline-block;
  font-size: 13px;
  line-height: 1.4;
  margin: 15px 10px;
  max-width: 100%;
  opacity: 0;
  padding: 10px 15px;
  text-align: justify;
  transform: translate(0, -10px);
  transition-duration: 300ms;
  transition-property: transform, opacity;
  transition-timing-function: ease-in;
  vertical-align: bottom;
  white-space: pre-wrap;
}
// .txt.show {
//   opacity: 1;
//   transform: translate(0, 0);
// }
.info.show {
  opacity: 1;
  transform: translate(0, 0);
}
.info {
  opacity: 0;
  transform: translate(0, 0);
  transition-duration: 200ms;
  transition-property: transform, opacity;
  transition-timing-function: ease-in;
  position: absolute;
  top: 0;
  left: 53px;
  width: 211px;
  height: 310px;
  background: rgba(34, 78, 219, 0.6);
  padding-top: 41px;
  padding: 37px 37px;
  .cicle {
    width: 11px;
    height: 11px;
    background: rgba(61, 238, 228, 1);
    border-radius: 5px;
    margin-right: 11px;
  }
  .title {
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
  }
  .two-title {
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    margin: 10px 0;
    margin-left: 22px;
  }
  .desc {
    width: 150px;
    height: 137px;
    padding-left: 22px;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    line-height: 28px;
  }
}
.look-detail.show {
  opacity: 1;
  transform: translate(0, 0);
}
.look-detail {
  position: absolute;
  opacity: 0;
  transform: translate(0, 0);
  transition-duration: 200ms;
  transition-property: transform, opacity;
  transition-timing-function: ease-in;
  bottom: 100px;
  left: 69px;
  width: 128px;
  height: 40px;
  background: linear-gradient(
    125.78deg,
    @lupay-text-color-theme 0%,
    rgba(38, 81, 220, 1) 50%,
    rgba(231, 238, 252, 1) 100%
  );
  border-radius: 6px;
  color: #ffffff;
}

.default-info {
  width: 100%;
  height: 100%;
  opacity: 1;
  top: 0;
  padding-top: 41px;
  padding: 37px;
  background: rgba(34, 78, 219, 0.5);
  transform: translate(0, 0);
  transition-duration: 200ms;
  transition-property: transform, opacity;
  transition-timing-function: ease-in;
  position: absolute;
  .default-text {
    writing-mode: vertical-lr;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 8px;
    position: relative;
    padding-top: 20px;
  }
  .default-text::before {
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgba(61, 238, 228, 1);
    position: absolute;
    left: 6px;
    top: 0px;
  }
}

.default-info:hover {
  opacity: 0;
}
</style>
