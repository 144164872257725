<template>
  <div class="home">
    <!-- 用服务铸就品牌 、用品牌创造价值 -->
    <div class="home-back">
      <div class="home-back-content container mx-auto">
        <h1 class="home-title wow animate__animated animate__fadeInDown">
          以永不停止的自进化力&nbsp;&nbsp;&nbsp;承载企业业务边界
        </h1>
        <h2 class="home-desc flex align-center wow animate__animated animate__fadeInDown animate__delay-0.5s">
          <div class="line"></div>
          璐付科技 数字化全场景智能营销服务商
        </h2>
        <div class="home-one-leave-title">四位一体的业务能力</div>
        <!-- <div class="home-one-leave-desc">
          灵活强大，可支持多行业、多场景应用的“一站式服务"，微信小程序、公众号、支付宝、
          app等快速开发嵌入，助力企业精细化运营，精准性营销。
        </div> -->
        <div class="home-card-content flex">
          <div class="home-card flex flex-col align-center" v-for="(item, index) in homeList" :key="index">
            <img :src="item.url" />
            <p class="title">{{ item.title }}</p>
            <p class="desc">{{ item.desc }}</p>
          </div>
        </div>
        <div class="btn-tools">
          <!-- <button class="concat">立即联系</button> -->
          <button class="online">在线客服</button>
        </div>
      </div>
    </div>
    <!-- 七大行业服务 -->
    <div class="industry-services" id="industry-services">
      <div class="container mx-auto">
        <p class="one-leave-title">七大服务领域</p>
      </div>
      <SevenIndustryServices />
    </div>
    <!-- 四大能力保障 -->
    <div class="ability container mx-auto">
      <p class="one-leave-title">用数字记录我们的成就</p>
      <!-- <p class="one-leave-desc">
        璐付科技以100+成熟的运维技术团队，根据市场情况实时调整产品策略，为客户提供运营管理、售后服务、企业方案定制。
      </p>
      <div class="ability-card flex">
        <div class="card-col">
          <div class="h-100 flex flex-col align-center justify-center">
            <img src="@/assets/img/yunying.png" />
            <p class="title">运营管理保障</p>
            <p class="desc">客户提供运营管理、售后 服务、企业方案定制。</p>
          </div>
        </div>
        <div class="card-col">
          <div class="h-100 flex flex-col align-center justify-center">
            <img src="@/assets/img/pingzhi.png" />
            <p class="title">品质保障</p>
            <p class="desc">客户提供运营管理、售后 服务、企业方案定制。</p>
          </div>
        </div>
        <div class="card-col">
          <div class="h-100 flex flex-col align-center justify-center">
            <img src="@/assets/img/jishu.png" />
            <p class="title">技术保障</p>
            <p class="desc">客户提供运营管理、售后 服务、企业方案定制。</p>
          </div>
        </div>
        <div class="card-col">
          <div class="h-100 flex flex-col align-center justify-center">
            <img src="@/assets/img/fuwu.png" />
            <p class="title">服务保障</p>
            <p class="desc">客户提供运营管理、售后 服务、企业方案定制。</p>
          </div>
        </div>
      </div> -->
      <div class="ability-static">
        <div class="ability-static-bg"></div>
        <div class="ability-static-content grid h-100 grid-cols-2 grid-rows-2">
          <div class="ability-crcil-box flex align-center justify-center" v-for="(item, index) in numList" :key="index">
            <div class="ability-static-crcil flex align-center justify-center flex-col">
              <p class="number">
                <CountUp :endVal="item.endVal" :startVal="0" />{{ index === 0 ? '亿+' : index === 1 ? '万+' : '+' }}
              </p>
              <p class="tips">{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 业务全链路智能化 -->
    <div class="intelligence">
      <div class="container mx-auto">
        <p class="one-leave-title">优势产品</p>
        <!-- <p class="one-leave-desc">
          璐付拥有自己的运行平台及多项软件，平台可自主开发，可迭代升级，实现业务流程的线上化、数字化、场景化、自动化、和智能化
        </p> -->
      </div>
      <div class="accordion">
        <VueGridAccordion :width="accordionWidth" :height="200" :columns="4" :gutter="2" :images="imgs" />
      </div>
    </div>
    <!-- 创新福利管理理念，全面提升开放平台 -->
    <!-- <div class="welfare">
      <div class="container mx-auto">
        <p class="one-leave-title">创新福利管理理念，全面提升开放平台</p>
        <p class="one-leave-desc">深度打造更具弹性的员工福利解决方案</p>
        <div class="home-swiper">
          <HomeWelfareSwiper />
        </div>
      </div>
    </div> -->
    <!-- 新闻资讯 -->
    <!-- <div class="news">
      <div class="container mx-auto">
        <p class="one-leave-title">新闻资讯</p>
      </div>
      <HomeNewsSwiper />
    </div> -->
    <!-- 3000+品牌的客户选择 -->
    <div class="news">
      <div class="container mx-auto">
        <p class="one-leave-title">4000+品牌客户共同的选择</p>
      </div>
      <div class="container mx-auto">
        <HomeBrandSwiper />
      </div>
    </div>
  </div>
</template>

<script>
import VueGridAccordion from '@/components/common/accordion/vGridAccordion';
// import Accordion from '@/components/common/Accordion';
// import HomeNewsSwiper from '@/components/common/HomeNewsSwiper';
// import HomeWelfareSwiper from '@/components/common/HomeWelfareSwiper';
import HomeBrandSwiper from '@/components/common/HomeBrandSwiper';
import SevenIndustryServices from '@/components/common/SevenIndustryServices';
import CountUp from '@wjtools/vue-countup';

export default {
  components: {
    VueGridAccordion,
    // Accordion,
    // HomeNewsSwiper,
    // HomeWelfareSwiper,
    HomeBrandSwiper,
    CountUp,
    SevenIndustryServices
  },
  data() {
    return {
      imgs: [
        {
          url: require('@/assets/img/home/home-z-one.jpg'),
          text: {
            title: '服务七大领域',
            twoTitle: '',
            desc: '聚合资源、聚合渠道，赋能整合、重组分发，诠释互联网供应链生态体系。',
            defaultText: '璐付云链'
          },
          routerName: 'localLife'
        },
        {
          url: require('@/assets/img/home/home-z-two.png'),
          text: {
            title: '一站式营销服务',
            twoTitle: '',
            desc: '提供从咨询、运营、软件实施、商品供应、售后支持一站式服务，打通线上线下用户数据，构建私域用户池，通过营销自动化，提高用户复购率。',
            defaultText: '权益营销'
          },
          routerName: 'equity'
        },
        {
          url: require('@/assets/img/home/home-z-three.png'),
          text: {
            title: 'SAAS级商城',
            twoTitle: '',
            desc: '数字商城集成式服务应用模板化，搭建丰富服务场景，帮助企业自助配置商品、定价，快速创建属于自己的虚拟商城。',
            defaultText: '数字商城'
          },
          routerName: 'virtualmall'
        },
        {
          url: require('@/assets/img/home/home-z-fore.png'),
          text: {
            title: '定制一卡通',
            twoTitle: '',
            desc: '一卡通汇集视频会员、生活服务、文娱游戏等海量数字权益，解决企业员工关怀、营销赠礼等诸多场景',
            defaultText: '定制一卡通'
          },
          routerName: 'onecardSolution'
        }
      ],
      accordionWidth: 0,
      homeList: [
        {
          title: '开发嵌入',
          url: require('@/assets/img/home/home-bg-one.png'),
          desc: '100+技术团队，微信公众号、小程序、支付宝、app等一键部署...'
        },
        {
          title: '运营管理',
          url: require('@/assets/img/home/home-bg-two.png'),
          desc: '成熟的运维团队，根据市场情况实时调整产品策略...'
        },
        {
          title: '售后服务',
          url: require('@/assets/img/home/home-bg-three.png'),
          desc: '成熟的售前售后服务体系及标准化客诉处理流程...'
        },
        {
          title: '企业方案定制',
          url: require('@/assets/img/home/home-bg-fore.png'),
          desc: '多样化配套营销工具，打造专属营销活动方案...'
        }
      ],
      numList: [
        {
          endVal: 2,
          title: '服务用户人次'
        },
        {
          endVal: 10,
          title: '供应商品数'
        },
        {
          endVal: 4000,
          title: '客户数量'
        },
        {
          endVal: 6000,
          title: '合作商家数量'
        }
      ]
    };
  },
  mounted() {
    this.__hanldeWindowWidth__();
    new this.$wow.WOW().init({
      //新建实列
      boxClass: 'wow', //class名字
      animateClass: 'animate__animated', //animateclass动画库类名,和当前animate动画库决定
      offset: 0, //到元素距离触发动画（当默认为0）
      mobile: true, //在移动设备上触发动画（默认为true）
      live: true //对异步加载的内容进行操作（默认为true）
    });
  },
  methods: {
    /** 辅助#处理手风琴宽度 */
    __hanldeWindowWidth__() {
      const that = this;
      that.accordionWidth = document.body.clientWidth;
      window.onresize = () => {
        return (() => {
          that.accordionWidth = document.body.clientWidth;
        })();
      };
    }
  }
};
</script>

<style lang="less" scoped>
.home {
  margin-bottom: 50px;
  .home-back {
    background-color: @lupay-bg-color-grey-3;
    background-image: url('../../assets/img/home/home-banner.png');
    height: 1000px;
    // background-size: 100% 100%;
    background-position: center;
    background-size: center center;
    padding-top: 77px;
    &-content {
      padding-left: 56px;
      padding-top: 66px;
    }
    .home-title {
      color: @lupay-text-color-theme;
      font-size: 44px;
      font-weight: 500;
    }
    .home-desc {
      color: rgba(34, 78, 219, 0.9);
      font-size: 24px;
    }
    .line {
      width: 84px;
      height: 1px;
      background-color: rgba(60, 102, 239, 1);
      margin-right: 17px;
    }
    .home-one-leave-title {
      margin-top: 107px;
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      font-weight: 500;
    }
    .home-one-leave-desc {
      width: 553px;
      height: 46px;
      color: rgba(0, 0, 0, 1);
      font-size: 14px;
      margin-top: 5px;
      font-family: 'Noto Sans SC';
      font-weight: 300;
    }

    .home-card-content {
      margin-top: 45px;
      .home-card {
        width: 130px;
        height: 200px;
        background: rgba(255, 255, 255, 1);
        border-radius: 6px;
        padding-top: 20px;
        margin-right: 20px;
        img {
          width: 72px;
          height: 72px;
        }
        .title {
          color: rgba(0, 0, 0, 0.8);
          font-size: 14px;
          margin: 5px 0;
        }
        .desc {
          color: rgba(0, 0, 0, 0.6);
          font-size: 12px;
          padding: 0 10px;
          font-weight: 200;
        }
      }
      .home-card:hover {
        box-shadow: 0px 3px 10px rgba(34, 78, 219, 0.2);
      }
    }

    .btn-tools {
      margin-top: 48px;
      .concat {
        width: 160px;
        height: 54px;
        background: linear-gradient(
          125.78deg,
          @lupay-text-color-theme 0%,
          rgba(38, 81, 220, 1) 50%,
          rgba(231, 238, 252, 1) 100%
        );
        border-radius: 4px;
        color: #fff;
      }
      .online {
        width: 160px;
        height: 54px;
        border: 1px solid @lupay-text-color-theme;
        border-radius: 4px;
        margin-left: 8px;
        color: #2143ae;
        position: relative;
      }

      .online:hover::before {
        content: '微信扫一扫';
        display: block;
        width: 160px;
        top: 54px;
        left: -1px;
        position: absolute;
        background-size: 100% 100%;
        padding: 2px;
        border: 1px solid #f4f4f4;
        opacity: 1;
        transition-duration: 300ms;
        background-color: #fff;
        z-index: 8;
        text-align: center;
      }
      .online:hover::after {
        content: '';
        display: block;
        width: 160px;
        height: 160px;
        top: 80px;
        left: -1px;
        position: absolute;
        background-image: url('../../assets/img/lupaywechat.png');
        background-size: 100% 100%;
        padding: 2px;
        border: 1px solid #f4f4f4;
        opacity: 1;
        transition-duration: 300ms;
      }
    }
  }
  .industry-services {
    margin-top: -130px;
  }
  .ability {
    .one-leave-desc {
      width: 406px;
    }
  }
  .ability-card {
    margin-top: 50px;
    .card-col {
      width: 299px;
      height: 247px;
      background: @lupay-bg-color-white;
      border-radius: 12px;
      cursor: pointer;
      margin-right: 3px;
      div {
        width: 154px;
        margin: 0 auto;
        .title {
          color: @lupay-text-color-black-3;
          font-size: 16px;
          margin: 29px 0 13px 0;
        }
        .desc {
          color: @lupay-text-color-black-4;
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
    img {
      width: 48px;
      height: 51px;
    }
    .card-col:hover {
      box-shadow: 0px 3px 10px @lupay-shadow-color-2;
      transition: all 0.2s;
    }
  }
  .ability-static {
    position: relative;

    &-bg {
      width: 100%;
      height: 600px;
      background-image: url('../../assets/img/keji.png');
      background-size: 100% 100%;
      position: absolute;
      z-index: 0;
      opacity: 0.1;
    }

    .ability-static-content {
      background-color: #1b3891 !important;
      width: 100%;
      height: 600px;
      margin: 48px 0;
      z-index: 11;
    }

    .ability-crcil-box:nth-child(n) {
      .ability-static-crcil {
        border: 4px solid rgba(23, 195, 196, 1);
      }
    }

    .ability-crcil-box:nth-child(4n) {
      .ability-static-crcil {
        border: 4px dashed rgba(255, 54, 181, 1);
      }
    }

    .ability-crcil-box:nth-child(1) {
      .ability-static-crcil {
        border: 4px dashed rgba(255, 54, 181, 1);
      }
    }

    &-crcil {
      width: 210px;
      height: 210px;
      border-radius: 50%;
      .number {
        color: @lupay-text-color-white;
        font-size: 40px;
      }
      .tips {
        color: @lupay-text-color-white;
        font-size: 14px;
      }
    }
  }
  .intelligence {
    .one-leave-title {
      margin-bottom: 21px;
    }
    .accordion {
      margin: 45px 0;
      height: 520px;
    }
  }
  .welfare {
    .one-leave-title {
      margin-bottom: 21px;
    }
    .home-swiper {
      margin-top: 50px;
    }
  }
  .news {
    margin-top: 70px;
  }
}
</style>
